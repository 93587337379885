<template>
  <WidgetTableColumnFilterWrapper
    type="number"
    :filter-value="appliedResult"
    trigger-filter-by-input-click
    @applyFilter="applyFilter(emit)"
    @resetFilter="resetFilter(emit)">
    <template #input>
      <el-input
        :placeholder="formatFilterRange(appliedResult)"
        type="text"
        disabled
        class="clickable" />
    </template>
    <template #main>
      <el-row
        :gutter="20">
        <el-col :span="5">
          <el-radio
            v-model="radio"
            label="default">
            {{ $t("Равно") }}
          </el-radio>
        </el-col>
        <el-col :span="9">
          <el-input
            v-model="filterData.default"
            type="text" />
        </el-col>
      </el-row>
      <el-divider />
      <el-row
        :gutter="20"
        align="middle">
        <el-col :span="5">
          <el-radio
            v-model="radio"
            label="range">
            {{ $t("Между") }}
          </el-radio>
        </el-col>
        <el-col :span="9">
          <el-input
            v-model="filterData.range[0]"
            type="number"
            clearable />
        </el-col>
        <p>{{ $t('и') }}</p>
        <el-col :span="9">
          <el-input
            v-model="filterData.range[1]"
            type="number"
            clearable />
        </el-col>
      </el-row>
    </template>
  </WidgetTableColumnFilterWrapper>
</template>

<script setup>
import { formatFilterRange } from '@/utils/formatters'
import useFilter from './composition/useFilter.js'
import WidgetTableColumnFilterWrapper from './WidgetTableColumnFilterWrapper'

const emit = defineEmits(['applyFilter'])
const { filterData,
        appliedResult,
        radio,
        applyFilter,
        setDefaultFilter,
        resetFilter } = useFilter()
setDefaultFilter()
</script>

<style lang="scss" scoped>
  
</style>
